import SiteStore, {
    extractContentField,
    updateSiteStore,
    init,
    SiteStoreState,
} from "billeci-private-sitebuilder-common/dist/stores/SiteStore";
import { classNames } from "billeci-private-sitebuilder-common/dist/util/common-utilities";

import envRaw from "./generated/env.json";
import siteRaw from "./generated/site.json";

import { useEffect } from "react";
import { useStore } from "effector-react";

import Main from "./pages/Main";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

var s = (urlParams.get("s") ?? "").toLowerCase();

function App() {
    const site_store = useStore(SiteStore);
    useEffect(() => {
        let ussp = {
            env: envRaw,
            siteRaw: siteRaw,
        };
        /// jump to quiz if passed in on query string
        if (s === "q") {
            ussp = Object.assign(ussp, {
                quickLoadMode: true,
                state: SiteStoreState.QUIZ,
                quizTimestamp: new Date().toISOString(),
            });
        }
        updateSiteStore(ussp);
        init();
    }, []);
    return (
        <>
            {site_store.env.env && (
                <>
                    <main className={classNames("relative z-10")}>
                        <Main />
                    </main>
                    {extractContentField(site_store, "BG_VIDEO").value && (
                        <video
                            className={classNames(
                                "z-0 fixed min-w-full min-h-full hidden max-w-none right-0 bottom-0",
                                "xs:block"
                            )}
                            autoPlay
                            muted
                            loop
                            src={`${site_store.strippedUri}/media/${extractContentField(site_store, "BG_VIDEO").value}`}
                        />
                    )}
                </>
            )}
        </>
    );
}

export default App;
