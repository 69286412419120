import { BrowserRouter, Route, Switch } from "react-router-dom";

import SiteStore, { extractContentField } from "billeci-private-sitebuilder-common/dist/stores/SiteStore";
import { useStore } from "effector-react";

import { classNames } from "billeci-private-sitebuilder-common/dist/util/common-utilities";

import ThankYou from "billeci-private-sitebuilder-common/dist/pages/ThankYou";
import Posting from "billeci-private-sitebuilder-common/dist/pages/Posting";

import StateSwitch from "./StateSwitch";

function Main() {
    var queryParams = new URLSearchParams(window.location.search);
    const site_store = useStore(SiteStore);
    return (
        <>
            {["POSTING"].includes(site_store.state) || queryParams.get("p") ? (
                <Posting />
            ) : (
                <>
                    <main
                        className={classNames(
                            extractContentField(site_store, "BG_VIDEO").value ? "" : "bg-BG_IMAGE",
                            "min-h-screen bg-cover bg-fixed bg-top",
                            "bg-opacity-50"
                        )}
                    >
                        <div className={classNames("xs:pt-4", "md:pt-8")}>
                            <div className={classNames()}>
                                <div
                                    className={classNames(
                                        "w-full bg-white mx-auto",
                                        "xs:w-11/12 xs:rounded-md",
                                        "sm:w-7/8 sm:rounded-xl",
                                        "md:w-400"
                                    )}
                                >
                                    <Switch>
                                        <BrowserRouter basename={site_store.strippedUri}>
                                            <Route path="/thankyou" component={ThankYou} />
                                            <Route path="/" component={StateSwitch} exact />
                                        </BrowserRouter>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )}
        </>
    );
}

export default Main;
