import { useStore } from "effector-react";

import SiteStore from "billeci-private-sitebuilder-common/dist/stores/SiteStore";

// const Quiz = lazy(() => import("billeci-private-sitebuilder-common/dist/pages/Quiz"));
// const Form = lazy(() => import("billeci-private-sitebuilder-common/dist/pages/Form"));
// const Error = lazy(() => import("billeci-private-sitebuilder-common/dist/pages/Error"));

import Home from "./Home";

import Quiz from "billeci-private-sitebuilder-common/dist/pages/Quiz";
import Form from "billeci-private-sitebuilder-common/dist/pages/Form";

export default function StateSwitch() {
    const site_store = useStore(SiteStore);
    return (
        <>
            {site_store.state === "INIT" && <Home />}
            {site_store.state === "QUIZ" && !site_store.site.surveySystemEnable && <Quiz />}
            {site_store.state === "FORM" && <Form />}
        </>
    );
}
