import { useStore } from "effector-react";

import SiteStore, { extractContentField, track } from "billeci-private-sitebuilder-common/dist/stores/SiteStore";

import CarForm from "billeci-private-sitebuilder-common/dist/components/CarForm";

import { getPhone, classNames, renderHTML } from "billeci-private-sitebuilder-common/dist/util/common-utilities";

export default function Home() {
    const site_store = useStore(SiteStore);
    return (
        <>
            <div className={classNames("rounded-xl")}>
                <div
                    className={classNames(
                        "w-full p-2 grid grid-cols-5 bg-color-MAIN_HEADER",
                        "xs:rounded-tl-md xs:rounded-tr-md",
                        "sm:rounded-tl-xl sm:rounded-tr-xl"
                    )}
                >
                    <div className={classNames("col-span-3")}>
                        <img
                            className={extractContentField(site_store, "LOGO").css ?? ""}
                            alt="logo"
                            src={`${site_store.strippedUri}/media/${extractContentField(site_store, "LOGO").value}`}
                        />
                    </div>
                    <div className={classNames("col-span-2 grid items-center justify-items-center xs:justify-end")}>
                        <button className={classNames(extractContentField(site_store, "TOP_PHONE").css ?? "")}>
                            <a
                                className={classNames("underline")}
                                href={`tel:${getPhone(site_store)}`}
                                onClick={() =>
                                    track({ action: "click", what: "link", label: "yes", category: "phone" })
                                }
                            >
                                {getPhone(site_store)}
                            </a>
                        </button>
                    </div>
                </div>
                <div className={classNames("w-full bg-color-MAIN_BACKGROUND pt-8 justify-items-center", "md:pb-5")}>
                    <div className={extractContentField(site_store, "TOP_BANNER_1").css ?? ""}>
                        {renderHTML(extractContentField(site_store, "TOP_BANNER_1").value ?? "")}
                    </div>
                    <div className={extractContentField(site_store, "TOP_BANNER_2").css ?? ""}>
                        {renderHTML(extractContentField(site_store, "TOP_BANNER_2").value ?? "")}
                    </div>
                </div>
                {renderHTML(
                    extractContentField(site_store, "CONTENT_ABOVE_FORM").value ?? "",
                    extractContentField(site_store, "CONTENT_ABOVE_FORM").css ?? ""
                )}
                <div className={classNames("w-full justify-center pt-6 pb-6")}>
                    <CarForm />
                </div>
                {renderHTML(
                    extractContentField(site_store, "CONTENT_BELOW_FORM").value ?? "",
                    extractContentField(site_store, "CONTENT_BELOW_FORM").css ?? ""
                )}
            </div>
        </>
    );
}
